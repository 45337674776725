<template>
  <div id="meetJitsi" class="j-meet"></div>
  <div class="no-items">
    <div class="title">Подключение к конференции...</div>
  </div>
</template>

<script setup>
import { loadScript, hashCode } from '~/utils/jitsi'
// import { getToken } from '@/use/authorization'
import { getMentiProfile, getMyMentorProfile, getMentiMeet, getMentorMeet, closeMeet } from '@/api/mentoring'

useHead({
  title: 'Конференция'
})

let API = ref(null)
const user = inject('user')
const router = useRouter()
const route = useRoute()
const related = ref(null)
const confName = ref('')
const userNick = ref('')
const meetPass = ref('')
const toolbarButtons = ref([])
const meetData = ref(null)
const preEndCount = ref(10)
let apiMeet

async function EndMeet () {
  if (meetData.value.datetime_end < new Date((new Date()).getTime() + preEndCount.value * 60000)) {
    const minDiff = Math.round((meetData.value.datetime_end - new Date()) / 60000)
    apiMeet.executeCommand('showNotification', {
      uid: 'notify.preEnd',
      title: 'Встреча подходит к концу',
      description: 'До конца встречи осталось ' + minDiff.toString() + (preEndCount.value > 4 ? ' минут' : (preEndCount.value > 1 ? ' минуты' : ' минута'))
    })
    if (preEndCount.value > 5) { preEndCount.value = 5 }
    if (preEndCount.value <= 5) { preEndCount.value-- }
  }
  if (meetData.value.datetime_end < new Date()) {
    apiMeet.executeCommand('showNotification', {
      uid: 'notify.preEnd',
      title: 'Встреча завершена',
      description: 'Встреча завершена, автоматический возврат к списку...'
    })
    if (user.value.is_mentor) {
      closeMeet(meetData.value.id)
    }
    apiMeet.executeCommand('hangup')
  }
  setTimeout(() => EndMeet(), 10000)
}

onMounted(async () => {
  window.onbeforeunload = () => 'Вы действительно хотите покинуть страницу со встречей?'

  meetData.value = await (user.value.is_mentor ? getMentiMeet : getMentorMeet)(route.params.meet)

  if (meetData.value.datetime_end < new Date()) {
    // TODO: можно доделать уведомление
    router.push(route.fullPath.substring(0, route.fullPath.length - route.params.meet.length - 6))
  }

  API = await loadScript('https://meet.mentoring.digital/libs/external_api.min.js', 'JitsiMeetExternalAPI')
  related.value = await (user.value.is_mentor ? getMentiProfile : getMyMentorProfile)(route.params.id)
  if (!related.value) throw createForbiddenError()

  console.log('meet_' + meetData.value.mentor_id.toString() + '_' + (((new Date(meetData.value.datetime_start)).toISOString()).replaceAll('-', '_').replaceAll('.', '_').replaceAll(':', '_')));
  confName.value = 'meet_' + meetData.value.mentor_id.toString() + '_' + (((new Date(meetData.value.datetime_start)).toISOString()).replaceAll('-', '_').replaceAll('.', '_').replaceAll(':', '_'))
  meetPass.value = hashCode(confName.value).toString()
  userNick.value = user.value.personal.first_name + ' ' + user.value.personal.last_name

  // if (user.value.is_mentor) {
  //   toolbarButtons.value = [
  //     'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
  //     'fodeviceselection', 'hangup', 'chat', 'recording',
  //     'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
  //     'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
  //     'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
  //     'e2ee', 'localrecording', 'security'
  //   ]
  // } else {
  //   toolbarButtons.value = [
  //     'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
  //     'fodeviceselection', 'hangup', 'chat',
  //     'settings', 'raisehand',
  //     'videoquality', 'shortcuts',
  //     'download', 'help'
  //   ]
  // }

  toolbarButtons.value = [
    'microphone', 'camera', 'fullscreen', 'desktop', 'hangup', 'tileview'
  ]

  const recordOptions = {
    mode: 'stream',
    youtubeStreamKey: meetData.value.stream_url
  }

  const options = {
    // tokenAuthUrl: 'https://jvillanueva-d1bc55af.localhost.run/station/test/jwt/{room}/{roleUpgrade}',
    // jwt: getToken(),
    roomName: confName.value,
    width: '100%',
    height: '100%',
    parentNode: document.querySelector('#meetJitsi'),
    userInfo: {
      email: user.value.personal.email,
      displayName: userNick.value
    },
    // password: meetPass.value,
    configOverwrite: {
      defaultLanguage: 'ru',
      // disableDeepLinking: true,
      prejoinConfig: { enabled: false },
      toolbarButtons: toolbarButtons.value,
      hideConferenceSubject: true,
      disabledNotifications: [
        'notify.grantedTo',
        'notify.moderationStartedTitle',
        'dialog.liveStreaming',
        'liveStreaming.busy', // shown when livestreaming service is busy
        'liveStreaming.failedToStart', // shown when livestreaming fails to start
        'liveStreaming.unavailableTitle' // shown when livestreaming service is not reachable
      ]
      // notifications: [
      //   'notify.disconnected', // shown when a participant has left
      //   'notify.connectedOneMember', // show when a participant joined
      //   'notify.preEnd'
      // ]
    },
    interfaceConfigOverwrite: {
      HIDE_INVITE_MORE_HEADER: true,
      DISABLE_FOCUS_INDICATOR: true,
      SHOW_WATERMARK_FOR_GUESTS: false,
      SHOW_BRAND_WATERMARK: false,
      SHOW_JITSI_WATERMARK: false,
      SHOW_CHROME_EXTENSION_BANNER: false,
      SETTINGS_SECTIONS: ['devices'],
      MOBILE_APP_PROMO: false,
      LANG_DETECTION: true
    },
    onload: (event) => {
      setTimeout(() => EndMeet(), 10000)
    }
  }
  apiMeet = new API('meet.mentoring.digital', options)
  apiMeet.addListener('participantJoined', (data) => {
    if (!user.value.is_mentor) {
      apiMeet.executeCommand('grantModerator', data.id)
    } else {
      apiMeet.executeCommand('startRecording', recordOptions)
    }
  })
  apiMeet.on('passwordRequired', function () {
    apiMeet.executeCommand('password', meetPass.value)
  })
  apiMeet.addListener('participantRoleChanged', (data) => {
    if (data.role === 'moderator') {
      // apiMeet.executeCommand('password', meetPass.value)
      const users = apiMeet.getParticipantsInfo()
      if (users.length > 1) {
        apiMeet.executeCommand('startRecording', recordOptions)
      }
      users.forEach((meetUser, index) => {
        if (meetUser.formattedDisplayName.slice(-5) !== ' (me)' && user.value.is_mentor) {
          // Тут надо придумать как забрать права Owner у не-ментора, пока оставим
          console.log('Try revoke ownership')
          // apiMeet.executeCommand('revokeOwner', meetUser.participantId)
        }
      })
    }
  })
  apiMeet.on('recordingStatusChanged', function (data) {
    if (data.error) console.error('Recording error:', data.error)
    if (!data.on && data.error !== 'unexpected-request') {
      setTimeout( () => apiMeet.executeCommand('startRecording', recordOptions), 5000)
    }
  })
  apiMeet.addListener('participantLeft', (id) => {
    if (user.value.is_mentor) {
      // apiMeet.executeCommand('stopRecording', { mode: 'file' })
    }
  })
  apiMeet.addListener('readyToClose', () => {
    apiMeet.executeCommand('stopRecording', { mode: 'stream' })
    router.push(route.fullPath.substring(0, route.fullPath.length - route.params.meet.length - 6))
  })
  apiMeet.executeCommand('displayName', userNick.value)
  apiMeet.executeCommand('avatarUrl', user.value.personal.avatar_url)
})
</script>

<style scoped>
.j-meet {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
